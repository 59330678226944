import axios from "axios";

const serverError = (error) => {
    if (!error.response)
        error.message = 'Servicio no disponible'
    else {
        error.status = error.response.status
        if (error.status == 401)
            error.message = "No cuenta con permisos para consultar"
        else
            error.message = "Ocurrió un error en el servidor"
    }
    throw error;
}

export async function getServerRequest(url, config) {
    try {
        return await axios
            .get(url, config)
            .then(res => { return res.data; })
    } catch (error) {
        serverError(error);
    }
}

export async function postServerRequest(url, data, config) {
    try {
        return await axios
            .post(url, data, config)
            .then(res => { return res.data; })
            .catch(error => { throw error; });
    } catch (error) {
        serverError(error);
    }
}

export async function putServerRequest(url, data, config) {
    try {
        return await axios
            .put(url, data, config)
            .then(res => { return res.data; })
            .catch(error => { throw error; });
    } catch (error) {
        serverError(error);
    }
}

export async function patchServerRequest(url, data, config) {
    try {
        return await axios
            .patch(url, data, config)
            .then(res => { return res.data; })
            .catch(error => { throw error; });
    } catch (error) {
        serverError(error);
    }
}

export async function deleteServerRequest(url, config) {
    try {
        return await axios
            .delete(url, config)
            .then(res => { return res.data; })
            .catch(error => { throw error; });
    } catch (error) {
        serverError(error);
    }
}